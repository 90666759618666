import React, { Component } from "react";
import { formatDate } from "../../common/services/utilities";
import Table from "../../common/components/table/table";
import { withTranslation } from "react-i18next";

class AlbertaEnvironmentalIssuesTable extends Component {
  columns = [
    //{path: 'TransactionId', label: this.props.t("TransactionId")},
    { path: "TransactionDate", label: this.props.t("TransactionDate") },
    { path: "Owner", label: this.props.t("Owner") },
    { path: "Quantity", label: this.props.t("Quantity") },
    { path: "Registry", label: this.props.t("Registry") },
    { path: "Vintage", label: this.props.t("Vintage") },
    {
      path: "QuantificationProtocol",
      label: this.props.t("QuantificationProtocol"),
    },
    { path: "SerialStart", label: this.props.t("SerialStart") },
    { path: "SerialEnd", label: this.props.t("SerialEnd") },
  ];

  mapToViewModel(issues) {
    return issues.map((issue, index) => ({
      index: index,
      Id: issue.Id,
      TransactionId: issue.TransactionId,
      TransactionDate: formatDate(issue.TransactionDate),
      Owner: issue.Owner,
      Quantity: issue.Quantity,
      Registry: issue.Registry,
      Vintage: issue.Vintage,
      QuantificationProtocol: issue.QuantificationProtocol,
      SerialStart: issue.SerialStart,
      SerialEnd: issue.SerialEnd,
    }));
  }

  render() {
    const { issues, onSort, sortColumn, onRowClick } = this.props;

    const mappedIssues = this.mapToViewModel(issues);

    return (
      <Table
        className="table-extra-small"
        columns={this.columns}
        sortColumn={sortColumn}
        onSort={onSort}
        data={mappedIssues}
        onRowClick={onRowClick}
        valueProperty="index"
      />
    );
  }
}

export default withTranslation(["marketdata"])(AlbertaEnvironmentalIssuesTable);
