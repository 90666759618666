import React, { Component } from "react";

class TableHeader extends Component {
  raiseSort = (path) => {
    const sortColumn = { ...this.props.sortColumn } || {
      path: "name",
      order: "asc",
    };
    if (sortColumn.path === path)
      sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
    else {
      sortColumn.path = path;
      sortColumn.order = "asc";
    }
    this.props.onSort(sortColumn);
  };

  renderSortIcon = (column) => {
    const sortColumn = this.props.sortColumn || { path: "name", order: "asc" };

    if (column.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <i className="fa fa-sort-asc"></i>;
    return <i className="fa fa-sort-desc"></i>;
  };

  render() {
    const { onSort, columns } = this.props;

    return (
      <thead>
        <tr>
          {columns.map((column) => (
            <th
              className={`${column.className ?? ""} ${
                onSort ? "clickable" : ""
              }`}
              key={column.path || column.key}
              onClick={onSort ? () => this.raiseSort(column.path) : undefined}
            >
              {column.label} {this.renderSortIcon(column)}
            </th>
          ))}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
