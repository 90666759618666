import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { getSummaryReportByDateRange } from "../../../common/services/marketdata/ngxSettlementService";
import { getNymexSettlementsByDateRange } from "../../../common/services/marketdata/nymexSettlementService";
import { getExchangeRatesByDateRange } from "../../../common/services/marketdata/bankOfCanadaService";
import { getMarkets } from "../../../common/services/markets/marketService";
import Input from "../../../common/components/form/input";
import SettlementsCombinedTable from "./settlementsCombinedTable";
import FontAwesome from "react-fontawesome";

//import Loading from "../../common/components/loading/loading";

class SettlementsExport extends Component {
  state = {
    fromDate: "",
    toDate: "",
    maxDate: "",
    minDate: "2024-01-01", //Stub
    //loading indicators:
    isLoading: true,
  };

  async componentDidMount() {
    const { marketDate } = this.props;

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);

    //const marketDate = new Date(currentDate).toLocaleDateString("fr-CA");

    const minDate = new Date(currentDate);
    minDate.setDate(minDate.getDate() - 30);
    const minDateText = new Date(minDate).toLocaleDateString("fr-CA");

    // const currentDate = new Date();
    // currentDate.setDate(currentDate.getDate() - 1);

    // const minDate = new Date(currentDate);
    // minDate.setDate(minDate.getDate() - 30);
    // const minDateText = new Date(minDate).toLocaleDateString("fr-CA");

    // this.setState({
    //   marketDate: marketDate,
    //   maxDate: marketDate,
    //   minDate: minDateText,
    //   isLoading: true,
    // });

    this.setState({
      maxDate: currentDate.toLocaleDateString("fr-CA"),
      minDate: minDateText,
    });

    await this.loadData(marketDate, marketDate);
  }

  async loadData(fromDate, toDate) {
    this.setState({ fromDate: fromDate, toDate: toDate });

    const markets = await getMarkets();
    const aesoMarket = markets.find((x) => x.name === "AESO");
    const albertaGasMarket = markets.find((x) => x.name === "AECO-C");

    const [
      ngxPowerSettles,
      ngxGasSettles,
      nymexGasSettles,
      nymexCrudeSettles,
      exchangeRates,
    ] = await Promise.all([
      this.loadNgxPowerSettles(aesoMarket.marketId, fromDate, toDate),
      this.loadNgxGasSettles(albertaGasMarket.marketId, fromDate, toDate),
      this.loadNymexGasSettles(fromDate, toDate),
      this.loadNymexCrudeSettles(fromDate, toDate),
      this.loadExchangeRates(fromDate, toDate),
    ]);

    //DateString normalization function.
    function normalizeDate(dateString) {
      return dateString.replace("T00:00:00Z", "T00:00:00");
    }

    // Configure your arrays with unique prefixes
    const joinedCore = this.fullOuterJoin(
      {
        array: ngxPowerSettles
          .filter((x) => x.termName.indexOf("D+") !== 0)
          .map((item) => ({
            ...item,
            marketDate: normalizeDate(item.marketDate),
          })), //Suppress all the extra dailies
        prefix: "NGXPower",
      },
      {
        array: ngxGasSettles
          .filter((x) => x.termName.indexOf("D+") !== 0)
          .map((item) => ({
            ...item,
            marketDate: normalizeDate(item.marketDate),
          })), //Suppress all the extra dailies
        prefix: "NGXGas",
      },
      { array: nymexGasSettles, prefix: "NYMEXGas" },
      { array: nymexCrudeSettles, prefix: "NYMEXCrude" }
    );

    // Then merge exchange rates (different join logic)
    const mergedDataFull = joinedCore.map((entry) => ({
      ...entry,
      FX_Rate:
        exchangeRates.find((fx) => fx.date === entry.marketDate)?.value || null,
    }));

    // // Add exchange rates with no matching marketDate
    // exchangeRates.forEach((fx) => {
    //   if (!joinedCore.some((entry) => entry.marketDate === fx.date)) {
    //     mergedDataFull.push({
    //       marketDate: fx.marketDate,
    //       beginDate: null,
    //       endDate: null,
    //       FX_Rate: fx.value,
    //       // Add nulls for other fields if needed
    //     });
    //   }
    // });

    //Get the end of the year five years from now.
    const futureDateLimit = new Date(toDate);

    // Add 20 years to the year component -- blow this date out so we show all data.
    futureDateLimit.setFullYear(futureDateLimit.getFullYear() + 20);
    // Set the month to December (11, since months are zero-indexed) and day to 31
    futureDateLimit.setMonth(11); // December
    futureDateLimit.setDate(31); // Last day of the month

    const mergedData = mergedDataFull.filter(
      (x) => new Date(x.endDate) <= futureDateLimit
    );

    this.setState({ isLoading: false, mergedData: mergedData });
  }

  async loadNgxPowerSettles(aesoMarket, fromDate, toDate) {
    return await getSummaryReportByDateRange(aesoMarket, fromDate, toDate);
  }

  async loadNgxGasSettles(albertaGasMarket, fromDate, toDate) {
    return await getSummaryReportByDateRange(
      albertaGasMarket,
      fromDate,
      toDate
    );
  }

  async loadNymexGasSettles(fromDate, toDate) {
    return await getNymexSettlementsByDateRange("NG", fromDate, toDate);
  }

  async loadNymexCrudeSettles(fromDate, toDate) {
    return await getNymexSettlementsByDateRange("CL", fromDate, toDate);
  }

  async loadExchangeRates(fromDate, toDate) {
    return await getExchangeRatesByDateRange("USD%2FCAD", fromDate, toDate);
  }

  fullOuterJoin = (...arraysWithConfig) => {
    // Collect all composite keys first
    const allCompositeKeys = new Set();
    const keyFields = ["marketDate", "beginDate", "endDate"];

    // First pass: Gather all unique composite keys
    arraysWithConfig.forEach(({ array }) => {
      array.forEach((entry) => {
        const key = keyFields.map((f) => entry[f] ?? "NULL").join("|");
        allCompositeKeys.add(key);
      });
    });

    // Convert to array of key components
    const baseEntries = Array.from(allCompositeKeys).map((keyStr) => {
      const [marketDate, beginDate, endDate] = keyStr.split("|");
      return {
        marketDate: marketDate !== "NULL" ? marketDate : null,
        beginDate: beginDate !== "NULL" ? beginDate : null,
        endDate: endDate !== "NULL" ? endDate : null,
      };
    });

    // Second pass: Merge data with namespacing
    return baseEntries.map((baseEntry) => {
      const merged = { ...baseEntry };

      arraysWithConfig.forEach(({ array, prefix }) => {
        const match = array.find((entry) =>
          keyFields.every(
            (field) =>
              entry[field] === merged[field] ||
              (entry[field] === undefined && merged[field] === null)
          )
        );

        if (match) {
          Object.entries(match).forEach(([key, value]) => {
            if (!keyFields.includes(key)) {
              merged[`${prefix}_${key}`] = value;
            }
          });
        }
      });

      return merged;
    });
  };

  handleFromDateChange = (fromDate) => {
    this.setState({
      fromDate: fromDate,
      isLoading: true,
    });

    const { toDate } = this.state;

    (async () => {
      await this.loadData(fromDate, toDate);
    })();
  };

  handleToDateChange = (toDate) => {
    this.setState({
      fromDate: toDate,
      isLoading: true,
    });

    const { fromDate } = this.state;

    (async () => {
      await this.loadData(fromDate, toDate);
    })();
  };

  render() {
    const { fromDate, toDate, maxDate, minDate, mergedData, isLoading } =
      this.state;

    const { t } = this.props;

    return (
      <div className="container-fluid print" style={{ height: "100%" }}>
        <div className="row no-print" style={{ height: "7%" }}>
          <div className="col-md-3">
            <Input
              type="date"
              min={minDate}
              max={[maxDate, toDate].sort()[0]}
              onKeyDown={(e) => e.preventDefault()}
              // onFocus={(e) => e.blur()}
              onChange={(e) => this.handleFromDateChange(e.currentTarget.value)}
              value={fromDate}
              label={t("FromDate")}
            />
          </div>
          <div className="col-md-3">
            <Input
              type="date"
              min={[minDate, fromDate].sort()[1]}
              max={maxDate}
              onKeyDown={(e) => e.preventDefault()}
              // onFocus={(e) => e.blur()}
              onChange={(e) => this.handleToDateChange(e.currentTarget.value)}
              value={toDate}
              label={t("ToDate")}
            />
          </div>
        </div>
        <div className="row no-print" style={{ height: "93%" }}>
          <div className="col-md-12">
            <SettlementsCombinedTable
              settlements={mergedData}
              isLoading={isLoading}
              title={t("SettlementsTable")}
              currencySymbol="$"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(SettlementsExport);
