import http from "../app/httpService";

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API + "enmaxRate/";

export async function getRateOfLastResortByMarketDate(marketDate) {
  const { data: rolr } = await http.get(
    apiEndpoint + "RateOfLastResort/" + marketDate
  );
  return rolr;
}

export async function getRateOfLastResortByDateRange(fromDate, toDate) {
  const { data: rateOfLastResort } = await http.get(
    apiEndpoint + "RateOfLastResort/" + fromDate + "/" + toDate
  );
  return rateOfLastResort;
}

export async function getRegulatedRateOptionByDateRange(fromDate, toDate) {
  const { data: regulatedRateOption } = await http.get(
    apiEndpoint + "RegulatedRateOption/" + fromDate + "/" + toDate
  );
  return regulatedRateOption;
}
