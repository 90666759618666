import http from "../app/httpService";

const apiEndpoint =
  process.env.REACT_APP_MARKET_DATA_API + "bankOfCanadaExchangeRate/";

export async function getExchangeRatesByDateRange(
  currencyPair,
  fromDate,
  toDate
) {
  const { data: exchangeRates } = await http.get(
    apiEndpoint + currencyPair + "/" + fromDate + "/" + toDate
  );
  return exchangeRates;
}
