import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import Loading from "../../common/components/loading/loading";

const LineGraph = ({ data, title, isLoading }) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    if (!svg.node()) {
      return;
    }

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 30, right: 20, bottom: 30, left: 50 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Parse date format for `forecastTransactionDate` in UTC to avoid time zone issues
    const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%SZ");

    // Format data, converting dates and calculating daily averages
    const formattedData = data
      .filter((d) => d.forecastTransactionDate && d.mostLikely !== null) // Ensure valid data
      .map((d) => ({
        forecastTransactionDate: parseDate(d.forecastTransactionDate),
        mostLikely: d.mostLikely,
        actual: d.actual,
      }));

    const normalizedData = formattedData.map((d) => ({
      ...d,
      normalizedDate: d3.timeDay(d.forecastTransactionDate), // Normalize to day
    }));

    // Calculate daily averages
    const dailyAveragesMap = d3.rollups(
      normalizedData,
      (v) => d3.mean(v, (d) => d.mostLikely),
      (d) => +d.normalizedDate // Use +d.normalizedDate to ensure consistent Map key format (as number timestamp)
    );

    // Create a Map for faster lookup of daily averages by date
    const dailyAveragesFilter = new Map(dailyAveragesMap);

    // Create a new array where each data point has its original timestamp
    // but the `avgMostLikely` is based on the day's average
    const dailyAverages = formattedData.map((d) => ({
      forecastTransactionDate: d.forecastTransactionDate,
      avgMostLikely: dailyAveragesFilter.get(
        +d3.timeDay(d.forecastTransactionDate)
      ), // Use +d3.timeDay to match the key type
    }));

    // Create SVG
    const chartSvg = svg
      .append("svg")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("viewBox", `0 0 ${parentWidth} ${parentHeight}`)
      .attr("preserveAspectRatio", "none") // Preserve aspect ratio
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(formattedData, (d) => d.forecastTransactionDate))
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max([
          ...formattedData.map((d) => d.mostLikely),
          ...dailyAverages.map((d) => d.avgMostLikely),
        ]),
      ])
      .nice()
      .range([innerHeight, 0]);

    // X and Y Axes
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale));

    chartSvg.append("g").call(d3.axisLeft(yScale));

    // Y Axis label
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -innerHeight / 2)
      .attr("y", -margin.left + 10)
      .attr("text-anchor", "middle")
      .attr("font-size", "14px")
      .attr("fill", "grey")
      .text("MW/h");

    // Add gridlines
    chartSvg
      .append("g")
      .selectAll(".grid")
      .data(yScale.ticks())
      .enter()
      .append("line")
      .attr("class", "grid")
      .attr("x1", 0)
      .attr("x2", innerWidth)
      .attr("y1", (d) => yScale(d))
      .attr("y2", (d) => yScale(d))
      .attr("stroke", "grey")
      .attr("opacity", "25%")
      .attr("stroke-width", 1);
    //.attr("stroke-dasharray", "3 3")

    // Line for mostLikely (dotted blue)
    const lineMostLikely = d3
      .line()
      .x((d) => xScale(d.forecastTransactionDate))
      .y((d) => yScale(d.mostLikely));

    chartSvg
      .append("path")
      .datum(formattedData)
      .attr("fill", "none")
      .attr("stroke", "#0096FF")
      .attr("stroke-width", 2)
      .attr("stroke-dasharray", "4 4")
      .attr("d", lineMostLikely);

    // Line for actual (dotted blue)
    const lineActual = d3
      .line()
      .x((d) => xScale(d.forecastTransactionDate))
      .y((d) => yScale(d.actual));

    chartSvg
      .append("path")
      .datum(formattedData)
      .attr("fill", "none")
      .attr("stroke", "green")
      .attr("stroke-width", 2)

      .attr("d", lineActual);

    // Line for daily averages (solid red)
    const lineAvgMostLikely = d3
      .line()
      .x((d) => xScale(d.forecastTransactionDate))
      .y((d) => yScale(d.avgMostLikely));

    chartSvg
      .append("path")
      .datum(dailyAverages)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("d", lineAvgMostLikely);

    // Legend
    const legend = chartSvg
      .append("g")
      .attr("transform", `translate(${innerWidth / 2}, -10)`); // Position legend above the bars

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text(title)
      .attr("fill", "grey");

    // Legend: Actual
    legend
      .append("line")
      .attr("x1", innerWidth / 4 - 150) // Starting point of the line
      .attr("y1", 5) // Position the line vertically
      .attr("x2", innerWidth / 4 + -120) // Ending point of the line (horizontal length)
      .attr("y2", 5) // Same vertical position as the start
      .attr("stroke", "green") // GReen color
      .attr("stroke-width", 2);

    legend
      .append("text")
      .attr("x", innerWidth / 4 - 110)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Actual");

    // Legend: Most Likely
    legend
      .append("line")
      .attr("x1", innerWidth / 4 - 60) // Starting point of the line
      .attr("y1", 5) // Position the line vertically
      .attr("x2", innerWidth / 4 + -30) // Ending point of the line (horizontal length)
      .attr("y2", 5) // Same vertical position as the start
      .attr("stroke", "#0096FF") // Blue color
      .attr("stroke-width", 2) // Line width
      .attr("stroke-dasharray", "4 4"); // Dashed line (4px dash, 4px space)

    legend
      .append("text")
      .attr("x", innerWidth / 4 - 25)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Most Likely");

    // Legend: Avg/Day
    legend
      .append("line")
      .attr("x1", innerWidth / 4 + 60) // Starting point of the line
      .attr("y1", 5) // Position the line vertically
      .attr("x2", innerWidth / 4 + 90) // Ending point of the line (horizontal length)
      .attr("y2", 5) // Same vertical position as the start
      .attr("stroke", "red") // Blue color
      .attr("stroke-width", 2); // Line width

    legend
      .append("text")
      .attr("x", innerWidth / 4 + 95)
      .attr("y", 10)
      .attr("font-size", "12px")
      .attr("fill", "grey")
      .text("Avg/Day");
  }, [data, title]);

  useEffect(() => {
    !isLoading && renderChart();

    return () => {
      /*         // Remove the tooltip when the component unmounts
        if (tooltipRef.current) {
            tooltipRef.current.remove();
        } */
    };
  }, [renderChart, isLoading]);

  useEffect(() => {
    const innerChartRef = svgRef;

    const handleResize = () => {
      !isLoading && renderChart();
    };

    const observer = new ResizeObserver(handleResize);
    if (innerChartRef.current) {
      observer.observe(innerChartRef.current);
    }

    // Clean up observer on component unmount
    return () => {
      if (innerChartRef.current) {
        observer.unobserve(innerChartRef.current);
      }
    };
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;
  return <svg ref={svgRef} width="100%" height="100%"></svg>;
};

export default LineGraph;
