import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../../common/components/table/table";
import { withTranslation } from "react-i18next";
import Loading from "../../../common/components/loading/loading";
import * as XLSX from "xlsx";
import { formatDate } from "../../../common/services/utilities";

class SettlementsCombinedTable extends Component {
  columns = [
    { path: "marketDate", label: this.props.t("MarketDate") },
    { path: "termName", label: this.props.t("TermName") },
    { path: "beginDate", label: this.props.t("BeginDate") },
    { path: "endDate", label: this.props.t("EndDate") },
    { path: "ngxPowerSettle", label: this.props.t("NgxPowerSettle") },
    { path: "ngxGasSettle", label: this.props.t("NgxGasSettle") },
    { path: "nymexGasSettle", label: this.props.t("NymexGasSettle") },
    { path: "nymexCrudeSettle", label: this.props.t("NymexCrudeSettle") },
    { path: "heatRate", label: this.props.t("HeatRate") },
    { path: "exchangeRate", label: this.props.t("ExchangeRate") },
  ];

  mapToViewModel(settlementsUnsorted) {
    const { currencySymbol } = this.props;

    const settlements = settlementsUnsorted.sort((a, b) => {
      // Compare marketDate ascending
      if (a.marketDate < b.marketDate) return -1;
      if (a.marketDate > b.marketDate) return 1;

      // If marketDate is the same, compare endDate ascending
      if (a.endDate < b.endDate) return -1;
      if (a.endDate > b.endDate) return 1;

      // If endDate is also the same, compare beginDate descending
      if (a.beginDate > b.beginDate) return -1;
      if (a.beginDate < b.beginDate) return 1;

      // If all dates are equal, maintain original order
      return 0;
    });

    return settlements.map((settlement) => ({
      marketDate: formatDate(settlement.marketDate) ?? null,
      termName:
        settlement.NYMEXCrude_termName ??
        settlement.NYMEXGas_termName ??
        settlement.NGXGas_termName ??
        settlement.NGXPower_termName ??
        null,
      beginDate: formatDate(settlement.beginDate) ?? null,
      endDate: formatDate(settlement.endDate) ?? null,
      ngxPowerSettle: settlement.NGXPower_current
        ? currencySymbol + settlement.NGXPower_current.toFixed(2)
        : "",
      ngxGasSettle: settlement.NGXGas_current
        ? currencySymbol + settlement.NGXGas_current.toFixed(4)
        : "",
      nymexGasSettle: settlement.NYMEXGas_settlePrice
        ? currencySymbol + settlement.NYMEXGas_settlePrice.toFixed(4)
        : "",
      nymexCrudeSettle: settlement.NYMEXCrude_settlePrice
        ? currencySymbol + settlement.NYMEXCrude_settlePrice.toFixed(4)
        : "",
      heatRate:
        settlement.NGXPower_current &&
        settlement.NGXGas_current &&
        settlement.NGXGas_current !== 0
          ? (settlement.NGXPower_current / settlement.NGXGas_current).toFixed(2)
          : "",
      exchangeRate: settlement.FX_Rate
        ? currencySymbol + settlement.FX_Rate.toFixed(4)
        : "",
    }));
  }

  downloadToExcel = (data) => {
    const excelData = data.map((settlement) => ({
      marketDate: formatDate(settlement.marketDate) ?? null,
      termName:
        settlement.NYMEXCrude_termName ??
        settlement.NYMEXGas_termName ??
        settlement.NGXGas_termName ??
        settlement.NGXPower_termName ??
        null,
      beginDate: formatDate(settlement.beginDate) ?? null,
      endDate: formatDate(settlement.endDate) ?? null,
      ngxPowerSettle: settlement.NGXPower_current
        ? settlement.NGXPower_current.toFixed(2)
        : "",
      ngxGasSettle: settlement.NGXGas_current
        ? settlement.NGXGas_current.toFixed(4)
        : "",
      nymexGasSettle: settlement.NYMEXGas_settlePrice
        ? settlement.NYMEXGas_settlePrice.toFixed(4)
        : "",
      nymexCrudeSettle: settlement.NYMEXCrude_settlePrice
        ? settlement.NYMEXCrude_settlePrice.toFixed(4)
        : "",
      heatRate:
        settlement.NGXPower_current &&
        settlement.NGXGas_current &&
        settlement.NGXGas_current !== 0
          ? (settlement.NGXPower_current / settlement.NGXGas_current).toFixed(2)
          : "",
      exchangeRate: settlement.FX_Rate ? settlement.FX_Rate.toFixed(4) : "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, this.props.title + ".xlsx");
  };

  render() {
    const { settlements, isLoading, title } = this.props;

    if (isLoading) return <Loading />;

    const tableData = this.mapToViewModel(settlements);

    return (
      <div>
        <div className="row">
          <div className="col-md-10">
            <h6>{title}</h6>
          </div>
          <div className="col-md-2" style={{ alignSelf: "flex-end" }}>
            <button
              style={{ float: "right" }}
              className="btn btn-sm btn-secondary no-print"
              onClick={() => this.downloadToExcel(settlements)}
              disabled={isLoading}
            >
              <FontAwesome name="download" /> {this.props.t("Excel")}
            </button>
          </div>
        </div>
        <Table
          className="table-extra-small"
          columns={this.columns}
          sortColumn={"termName"}
          data={tableData}
          valueProperty="termName"
        />
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(SettlementsCombinedTable);
