import http from "../app/httpService";

const apiEndpoint =
  process.env.REACT_APP_MARKET_DATA_API + "UsaEiaWeeklyNaturalGasStorage/";

export async function getEiaDailyStorage(fromDate, toDate) {
  const { data: dailyStorage } = await http.get(
    apiEndpoint + "DailyStorage/" + fromDate + "/" + toDate
  );
  return dailyStorage;
}
