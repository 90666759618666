import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import Loading from "../../common/components/loading/loading";

const DailyPoolPriceLineGraph = ({ data, isLoading }) => {
  const svgRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight;
    const margin = { top: 40, right: 10, bottom: 40, left: 30 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .attr("viewBox", `0 0 ${parentWidth} ${parentHeight}`)
      .attr("preserveAspectRatio", "none") // Preserve aspect ratio
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // // Parse dates and calculate monthly averages
    // const parseDate = d3.timeParse("%Y-%m-%dT%H:%M:%S");

    const monthlyData = d3
      .rollups(
        data,
        (v) => ({
          monthStart: d3.timeMonth.floor(new Date(v[0].marketDate)),
          avgPoolPrice: d3.mean(v, (d) => d.poolPrice),
        }),
        (d) => d3.timeMonth.floor(new Date(d.marketDate))
      )
      .map(([month, values]) => ({
        date: values.monthStart,
        poolPrice: values.avgPoolPrice,
      }));

    // Group data by year
    const groupedData = d3.group(monthlyData, (d) => d.date.getFullYear());

    // Prepare data for plotting
    const plotData = Array.from(groupedData, ([year, values]) => {
      return {
        year: year,
        values: values.map((d) => ({
          date: new Date(2000, d.date.getMonth(), 1),
          poolPrice: d.poolPrice,
        })),
      };
    });

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain([new Date(2000, 0, 1), new Date(2000, 11, 31)]) // Full year range
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(monthlyData, (d) => d.poolPrice)])
      .nice()
      .range([innerHeight, 0]);

    // Color scale for different years
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Create line generator
    const line = d3
      .line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.poolPrice))
      .defined((d) => !isNaN(d.poolPrice)); // Handle any potential NaN values

    // Find the most recent year
    const mostRecentYear = d3.max(plotData, (d) => d.year);

    // Draw lines for each year
    plotData.forEach((yearData) => {
      const path = line(yearData.values);
      chartSvg
        .append("path")
        .datum(yearData.values)
        .attr("fill", "none")
        .attr(
          "stroke",
          yearData.year === mostRecentYear ? "red" : colorScale(yearData.year)
        )
        .attr("stroke-width", yearData.year === mostRecentYear ? 2 : 1)
        .attr("d", path)
        .attr(
          "stroke-dasharray",
          yearData.year === mostRecentYear ? "0 0" : "2 2"
        );
    });

    // Add X axis
    chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(d3.timeMonth.every(1))
          .tickFormat(d3.timeFormat("%b"))
      );

    // Format for currency with 2 decimal places
    const formatCurrency = d3.format("$,.0f");

    // Add Y axis
    chartSvg.append("g").call(d3.axisLeft(yScale).tickFormat(formatCurrency));

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("AESO Historical Monthly Average Pool Price")
      .attr("fill", "grey");

    // Add legend at the bottom
    const legendHeight = 20;
    const legendLineWidth = 20;
    const legendItemWidth = 70;

    svg.attr("height", parentHeight + legendHeight + 10);

    const totalLegendWidth = plotData.length * legendItemWidth;

    const legend = chartSvg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 12)
      .attr("fill", "grey")
      .attr("text-anchor", "start")
      .attr(
        "transform",
        `translate(${(innerWidth - totalLegendWidth) / 2}, ${
          innerHeight + margin.bottom - legendHeight
        })`
      );

    plotData.forEach((yearData, i) => {
      const legendItem = legend
        .append("g")
        .attr("transform", `translate(${i * legendItemWidth}, 0)`);

      legendItem
        .append("line")
        .attr("x1", 0)
        .attr("y1", legendHeight / 2)
        .attr("x2", legendLineWidth)
        .attr("y2", legendHeight / 2)
        .attr(
          "stroke",
          yearData.year === mostRecentYear ? "red" : colorScale(yearData.year)
        )
        .attr("stroke-width", yearData.year === mostRecentYear ? 2 : 1)
        .attr(
          "stroke-dasharray",
          yearData.year === mostRecentYear ? "0 0" : "2 2"
        );

      legendItem
        .append("text")
        .attr("x", legendLineWidth + 5)
        .attr("y", legendHeight / 2)
        .attr("dy", "0.35em")
        .text(yearData.year);
    });
  }, [data]);

  useEffect(() => {
    !isLoading && renderChart();
  }, [renderChart, isLoading]);

  useEffect(() => {
    const handleResize = () => {
      if (!isLoading) {
        renderChart();
      }
    };

    // Observe the parent div for size changes
    const observer = new ResizeObserver(handleResize);
    if (svgRef.current?.parentNode) {
      observer.observe(svgRef.current.parentNode);
    }

    // Trigger chart rendering on mount
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      observer.disconnect();
    };
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;
  return <svg ref={svgRef} style={{ width: "100%", height: "100%" }}></svg>;
};

export default DailyPoolPriceLineGraph;
