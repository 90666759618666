import http from "../app/httpService";

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API + "tcGasSummary/";

export async function getTcGasSummaryByMarketDate(marketDate) {
  const { data: tcGasSummary } = await http.get(apiEndpoint + "" + marketDate);
  return tcGasSummary;
}

export async function getTcDailyStorage(fromDate, toDate) {
  const { data: dailyStorage } = await http.get(
    apiEndpoint + "DailyStorage/" + fromDate + "/" + toDate
  );
  return dailyStorage;
}
