import http from "../app/httpService";

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API + "nymexSettlement/";

export async function getNymexSettlementsByDateRange(
  commodityCode,
  fromDate,
  toDate
) {
  const { data: nymexSettlements } = await http.get(
    apiEndpoint + commodityCode + "/" + fromDate + "/" + toDate
  );
  return nymexSettlements;
}
