import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import Loading from "../../common/components/loading/loading";
import { sliderBottom } from "d3-simple-slider";

const AesoPoolPriceVsEnmaxRetailLineGraph = ({
  poolPriceData,
  regulatedRateData,
  rateOfLastResortData,
  isLoading,
  initialStartDate,
  initialEndDate,
}) => {
  const svgRef = useRef();
  const sliderRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight - 20;
    const margin = { top: 40, right: 10, bottom: 70, left: 55 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .attr("viewBox", `0 0 ${parentWidth} ${parentHeight}`)
      .attr("preserveAspectRatio", "none") // Preserve aspect ratio
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Parse dates and average by month
    const formatMonth = d3.timeFormat("%Y-%m");

    const averageByMonth = (data, dateField, valueField, multiplier = 1) => {
      const groupedData = d3.group(data, (d) => {
        const parsedDate = new Date(d[dateField]);
        if (!parsedDate || d[dateField] === null) {
          console.error(`Failed to parse date: ${d[dateField]}`);
          return null;
        }
        return formatMonth(parsedDate);
      });

      return Array.from(groupedData, ([key, values]) => {
        if (key === null) return null;
        return {
          marketDate: new Date(key),
          [valueField]: d3.mean(values, (d) => {
            const value = +d[valueField];
            if (isNaN(value)) {
              console.error(`Invalid ${valueField} value:`, d[valueField]);
              return null;
            }
            return value * multiplier;
          }),
        };
      }).filter((d) => d !== null);
    };

    const averagedRegulatedRateData = averageByMonth(
      regulatedRateData,
      "date",
      "price"
    );
    const averagedRateOfLastResortData = averageByMonth(
      rateOfLastResortData,
      "date",
      "unitPrice",
      10 /*True up to a common UOM*/
    );

    const minDate = new Date(
      Math.min(...averagedRegulatedRateData.map((x) => x.marketDate))
    );

    const averagedPoolPriceData = averageByMonth(
      poolPriceData.filter((x) => new Date(x.marketDate) >= minDate), //Limit the lookback to just those dates for which we have retail prices,
      "marketDate",
      "poolPrice"
    );

    // Calculate the difference for the bar graph
    const differenceData = averagedPoolPriceData
      .map((poolPrice) => {
        const date = poolPrice.marketDate;
        const rateOfLastResort = averagedRateOfLastResortData.find(
          (d) => d.marketDate.getTime() === date.getTime()
        );
        const regulatedRate = averagedRegulatedRateData.find(
          (d) => d.marketDate.getTime() === date.getTime()
        );
        const comparePrice = rateOfLastResort
          ? rateOfLastResort.unitPrice
          : regulatedRate
          ? regulatedRate.price
          : null;
        return {
          marketDate: date,
          difference:
            comparePrice !== null ? poolPrice.poolPrice - comparePrice : null,
        };
      })
      .filter((d) => d.difference !== null);

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain(
        d3.extent(
          [...averagedPoolPriceData, ...differenceData],
          (d) => d.marketDate
        )
      )
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([
        d3.min([
          ...averagedPoolPriceData.map((d) => d.poolPrice),
          ...averagedRegulatedRateData.map((d) => d.price),
          ...averagedRateOfLastResortData.map((d) => d.unitPrice),
          ...differenceData.map((d) => d.difference),
        ]),
        d3.max([
          ...averagedPoolPriceData.map((d) => d.poolPrice),
          ...averagedRegulatedRateData.map((d) => d.price),
          ...averagedRateOfLastResortData.map((d) => d.unitPrice),
          ...differenceData.map((d) => d.difference),
        ]),
      ])
      .nice()
      .range([innerHeight, 0]);

    // Create line generators
    const lineGenerator = d3
      .line()
      .x((d) => xScale(d.marketDate))
      .y((d) => yScale(d[Object.keys(d)[1]])); // Use the second property (value) for y

    // Draw lines
    const lines = [
      { data: averagedPoolPriceData, color: "#0096FF", label: "Pool Price" },
      {
        data: averagedRegulatedRateData,
        color: "springgreen",
        label: "Regulated Rate",
      },
      {
        data: averagedRateOfLastResortData,
        color: "violet",
        label: "Rate of Last Resort",
      },
    ];

    // Draw lines
    const linesGroup = chartSvg.append("g").attr("class", "lines");

    lines.forEach((line) => {
      linesGroup
        .append("path")
        .datum(line.data)
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", line.color)
        .attr("stroke-width", 2)
        .attr("d", lineGenerator);
    });

    // Add X axis
    const xAxis = chartSvg
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`);

    // Add Y axis
    const yAxis = chartSvg.append("g");

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("AESO Pool Price vs Enmax Regulated Rates")
      .attr("fill", "grey");

    // Add Y axis label
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("font-size", "14px")
      .text("Price ($/MWh)")
      .attr("fill", "grey");

    // Add legend
    const legendItems = [
      { color: "#0096FF", label: "Pool Price" },
      { color: "springgreen", label: "Regulated Rate" },
      { color: "violet", label: "Rate of Last Resort" },
      { color: "rgba(0, 255, 0, 0.5)", label: "Positive Difference" },
      { color: "rgba(255, 0, 0, 0.5)", label: "Negative Difference" },
    ];

    const legend = chartSvg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "end")
      .attr("transform", `translate(${innerWidth}, ${-10})`);

    legendItems.forEach((item, i) => {
      const legendItem = legend
        .append("g")
        .attr("transform", `translate(${-i * 120}, 0)`);

      if (i < 3) {
        legendItem
          .append("line")
          .attr("x1", -30)
          .attr("x2", -10)
          .attr("stroke", item.color)
          .attr("stroke-width", 2);
      } else {
        legendItem
          .append("rect")
          .attr("x", -30)
          .attr("y", -5)
          .attr("width", 20)
          .attr("height", 10)
          .attr("fill", item.color);
      }

      legendItem
        .append("text")
        .attr("x", -35)
        .attr("dy", "0.32em")
        .text(item.label)
        .attr("fill", "grey");
    });

    // Add slider
    const sliderSvg = d3
      .select(sliderRef.current)
      .attr("width", parentWidth)
      .attr("height", 50)
      .attr("class", "no-print")
      .attr("transform", `translate(0,-30)`); // Adjust vertical position;

    sliderSvg.selectAll("*").remove(); // Clear previous slider

    const sliderWidth = innerWidth; // Make slider even smaller

    const updateChart = (range) => {
      const [startDate, endDate] = range;

      const filteredLines = lines.map((line) => ({
        ...line,
        data: line.data.filter(
          (d) => d.marketDate >= startDate && d.marketDate <= endDate
        ),
      }));

      const filteredDifferenceData = differenceData.filter(
        (d) => d.marketDate >= startDate && d.marketDate <= endDate
      );

      xScale.domain([startDate, endDate]);

      const yScale = d3
        .scaleLinear()
        .domain([
          d3.min([
            ...filteredLines.map((line) =>
              d3.min(line.data, (d) => d[Object.keys(d)[1]])
            ),
            ...filteredDifferenceData.map((d) => d.difference),
          ]),
          d3.max([
            ...filteredLines.map((line) =>
              d3.max(line.data, (d) => d[Object.keys(d)[1]])
            ),
            ...filteredDifferenceData.map((d) => d.difference),
          ]),
        ])
        .nice()
        .range([innerHeight, 0]);

      const t = d3.transition().duration(50).ease(d3.easeLinear);

      // Update X axis
      xAxis
        .transition(t)
        .call(
          d3
            .axisBottom(xScale)
            .ticks(d3.timeMonth.every(3))
            .tickFormat(d3.timeFormat("%b %Y"))
        )
        .selectAll("text")
        .attr("transform", "rotate(-45)")
        .style("text-anchor", "end");

      yAxis
        .transition(t)
        .call(d3.axisLeft(yScale).tickFormat((d) => `$${d.toFixed(0)}`));

      linesGroup
        .selectAll(".line")
        .data(filteredLines)
        .join("path")
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", (d) => d.color)
        .attr("stroke-width", 2)
        .transition(t)
        .attr("d", (d) => lineGenerator(d.data));

      // Update bars
      const numBars = filteredDifferenceData.length;
      const fixedPadding = 2; // Adjust this value to control the fixed spacing

      let barWidth;

      if (numBars > 0) {
        // Calculate the bar width based on fixed padding
        barWidth = (innerWidth - (numBars - 1) * fixedPadding) / numBars;

        // If barWidth is negative, it means the fixedPadding is taking up too much space.
        // Reset barWidth to 0 in this case to avoid errors and unwanted behavior.
        barWidth = Math.max(0, barWidth);
      } else {
        barWidth = 0; // If there are no bars, set barWidth to 0.
      }

      chartSvg
        .selectAll(".bar")
        .data(filteredDifferenceData, (d) => d.marketDate) // Key function for smooth updates
        .join(
          (enter) =>
            enter
              .append("rect")
              .attr("class", "bar")
              .attr("x", (d, i) => {
                // Calculate the x position for each bar
                let barX = 0;
                if (numBars > 0) {
                  barX = i * (barWidth + fixedPadding);
                }
                return barX;
              })
              .attr("y", (d) =>
                d.difference > 0 ? yScale(d.difference) : yScale(0)
              )
              .attr("width", barWidth)
              .attr("height", (d) => Math.abs(yScale(d.difference) - yScale(0)))
              .attr("fill", (d) =>
                d.difference > 0
                  ? "rgba(0, 255, 0, 0.5)"
                  : "rgba(255, 0, 0, 0.5)"
              ),
          (update) =>
            update.call((update) =>
              update
                .transition(t)
                .attr("x", (d, i) => {
                  // Calculate the x position for each bar
                  let barX = 0;
                  if (numBars > 0) {
                    barX = i * (barWidth + fixedPadding);
                  }
                  return barX;
                })
                .attr("y", (d) =>
                  d.difference > 0 ? yScale(d.difference) : yScale(0)
                )
                .attr("width", barWidth)
                .attr("height", (d) =>
                  Math.abs(yScale(d.difference) - yScale(0))
                )
            ),
          (exit) => exit.transition(t).remove()
        );
    };

    // Throttle function
    const throttle = (func, limit) => {
      let lastFunc;
      let lastRan;
      return function () {
        const context = this;
        const args = arguments;
        if (!lastRan) {
          func.apply(context, args);
          lastRan = Date.now();
        } else {
          clearTimeout(lastFunc);
          lastFunc = setTimeout(function () {
            if (Date.now() - lastRan >= limit) {
              func.apply(context, args);
              lastRan = Date.now();
            }
          }, limit - (Date.now() - lastRan));
        }
      };
    };

    const throttledUpdateChart = throttle(updateChart, 30);

    const slider = sliderBottom()
      .min(
        d3.min(
          [...averagedPoolPriceData, ...differenceData],
          (d) => d.marketDate
        )
      )
      .max(
        d3.max(
          [...averagedPoolPriceData, ...differenceData],
          (d) => d.marketDate
        )
      )
      .width(sliderWidth)
      .tickFormat(d3.timeFormat("%Y-%m-%d"))
      .ticks(5)
      .default([
        initialStartDate ||
          d3.min(
            [...averagedPoolPriceData, ...differenceData],
            (d) => d.marketDate
          ),
        initialEndDate ||
          d3.max(
            [...averagedPoolPriceData, ...differenceData],
            (d) => d.marketDate
          ),
      ])
      .fill("#2196f3")
      .on("onchange", throttledUpdateChart);

    sliderSvg
      .append("g")
      .attr("transform", `translate(${(parentWidth - sliderWidth) / 2},10)`) // Adjust vertical position
      .call(slider);

    sliderSvg.selectAll(".slider text").style("fill", "grey");
    sliderSvg.selectAll(".tick text").style("opacity", "0");

    // Call updateChart with initial values
    updateChart([
      initialStartDate ||
        d3.min(
          [...averagedPoolPriceData, ...differenceData],
          (d) => d.marketDate
        ),
      initialEndDate ||
        d3.max(
          [...averagedPoolPriceData, ...differenceData],
          (d) => d.marketDate
        ),
    ]);
  }, [
    poolPriceData,
    regulatedRateData,
    rateOfLastResortData,
    initialStartDate,
    initialEndDate,
  ]);

  useEffect(() => {
    const handleResize = () => {
      if (!isLoading) {
        renderChart();
      }
    };

    // Observe the parent div for size changes
    const observer = new ResizeObserver(handleResize);
    if (svgRef.current?.parentNode) {
      observer.observe(svgRef.current.parentNode);
    }

    // Trigger chart rendering on mount
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      observer.disconnect();
    };
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <svg ref={svgRef} style={{ width: "100%" }}></svg>
      <svg ref={sliderRef} style={{ width: "100%" }}></svg>
    </div>
  );
};

export default AesoPoolPriceVsEnmaxRetailLineGraph;
