import http from "../app/httpService";

const apiEndpoint = process.env.REACT_APP_MARKET_DATA_API + "aeso/";

export async function getWindLongTermForecastByMarketDate(marketDate) {
  const { data: windLongTermForecast } = await http.get(
    apiEndpoint + "AesoWindLongTermForecast/" + marketDate
  );
  return windLongTermForecast;
}

export async function getSolarLongTermForecastByMarketDate(marketDate) {
  const { data: solarLongTermForecast } = await http.get(
    apiEndpoint + "AesoSolarLongTermForecast/" + marketDate
  );
  return solarLongTermForecast;
}

export async function getPoolPriceByMarketDate(fromDate, toDate) {
  const { data: poolPrice } = await http.get(
    apiEndpoint + "AesoPoolPrice/" + fromDate + "/" + toDate
  );
  return poolPrice;
}

export async function getDailyPoolPriceAverages(marketDate) {
  const { data: dailyPoolPriceAverages } = await http.get(
    apiEndpoint + "AesoPoolPrice/DailyAverages/" + marketDate
  );
  return dailyPoolPriceAverages;
}

export async function getInternalLoadByMarketDate(fromDate, toDate) {
  const { data: internalLoad } = await http.get(
    apiEndpoint + "AesoInternalLoad/" + fromDate + "/" + toDate
  );
  return internalLoad;
}

export async function getDailyInternalLoadAverages(marketDate) {
  const { data: dailyInternalLoadAverages } = await http.get(
    apiEndpoint + "AesoInternalLoad/DailyAverages/" + marketDate
  );
  return dailyInternalLoadAverages;
}

export async function getSummary(marketDate) {
  const { data: summary } = await http.get(
    apiEndpoint + "AesoPoolPrice/Summary/" + marketDate
  );
  return summary;
}

export async function getDailyOutageReport(marketDate) {
  const { data: dailyOutageReport } = await http.get(
    apiEndpoint + "AesoDailyOutageReport/" + marketDate
  );
  return dailyOutageReport;
}

export async function getMonthlyOutageForecastReport(marketDate) {
  const { data: monthlyOutageForecastReport } = await http.get(
    apiEndpoint + "AesoMonthlyOutageForecastReport/" + marketDate
  );
  return monthlyOutageForecastReport;
}

export async function getCurrentGenerationByMarketDate(marketDate) {
  const { data: currentGeneration } = await http.get(
    apiEndpoint + "AesoAssetCurrentGeneration/" + marketDate
  );
  return currentGeneration;
}

export async function getCurrentGenerationByMarketDateFuelType(marketDate) {
  const { data: currentGenerationByFuelType } = await http.get(
    apiEndpoint + "AesoAssetCurrentGeneration/FuelType/" + marketDate
  );
  return currentGenerationByFuelType;
}
