import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../common/components/table/table";
import { withTranslation } from "react-i18next";
import Loading from "../../common/components/loading/loading";
import * as XLSX from "xlsx";
import { formatDate } from "../../common/services/utilities";

class SettlementsTable extends Component {
  columns = [
    { path: "termName", label: this.props.t("TermName") },
    {
      path: "current",
      label: this.props.t("Current"),
      className: "right-align",
    },
    {
      path: "yesterdayDelta",
      label: this.props.t("YesterdayDelta"),
      content: (data) => (
        <span
          style={{ color: data.current >= data.yesterday ? "green" : "red" }}
        >
          <FontAwesome
            name={data.current >= data.yesterday ? "sort-asc" : "sort-desc"}
          />{" "}
          {data.yesterdayDelta}
        </span>
      ),
      className: "right-align",
    },
    {
      path: "mtdAverage",
      label: this.props.t("MtdAverage"),
      className: "right-align",
    },
    {
      path: "ytdAverage",
      label: this.props.t("YtdAverage"),
      className: "right-align",
    },
    { path: "ytdLow", label: this.props.t("YtdLow"), className: "right-align" },
    {
      path: "ytdHigh",
      label: this.props.t("YtdHigh"),
      className: "right-align",
    },
    ...(this.props.showHeatRate
      ? [
          {
            path: "heatRate",
            label: this.props.t("HeatRate"),
            content: (data) => (
              <span style={{ color: "SlateBlue" }}>{data.heatRate}</span>
            ),
            className: "right-align",
          },
        ]
      : []),
  ];

  mapToViewModel(settlements) {
    const { pricePrecision, currencySymbol } = this.props;

    return settlements.map((settlement) => ({
      termName: settlement.termName,
      current: currencySymbol + settlement.current.toFixed(pricePrecision),
      yesterday: currencySymbol + settlement.yesterday.toFixed(pricePrecision),
      yesterdayDelta:
        currencySymbol +
        (settlement.current - settlement.yesterday).toFixed(pricePrecision),
      mtdAverage:
        currencySymbol + settlement.mtdAverage.toFixed(pricePrecision),
      ytdAverage:
        currencySymbol + settlement.ytdAverage.toFixed(pricePrecision),
      ytdLow: currencySymbol + settlement.ytdLow.toFixed(pricePrecision),
      ytdHigh: currencySymbol + settlement.ytdHigh.toFixed(pricePrecision),
      heatRate: settlement.heatRate ? settlement.heatRate.toFixed(2) : "",
    }));
  }

  downloadToExcel = (data) => {
    const { pricePrecision, effectiveDate } = this.props;

    const excelData = data.map((settlement) => ({
      termName: settlement.termName ?? null,
      beginDate: formatDate(settlement.beginDate) ?? null,
      endDate: formatDate(settlement.endDate) ?? null,
      current: settlement.current.toFixed(pricePrecision) ?? null,
      yesterday: settlement.yesterday.toFixed(pricePrecision) ?? null,
      yesterdayDelta:
        (settlement.current - settlement.yesterday).toFixed(pricePrecision) ??
        null,
      mtdAverage: settlement.mtdAverage.toFixed(pricePrecision) ?? null,
      ytdAverage: settlement.ytdAverage.toFixed(pricePrecision) ?? null,
      ytdLow: settlement.ytdLow.toFixed(pricePrecision) ?? null,
      ytdHigh: settlement.ytdHigh.toFixed(pricePrecision) ?? null,
      heatRate: settlement.heatRate
        ? settlement.heatRate.toFixed(2)
        : null ?? null,
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      this.props.title + " " + formatDate(effectiveDate) + ".xlsx"
    );
  };

  render() {
    const { settlements, isLoading, title } = this.props;

    if (isLoading) return <Loading />;

    const tableData = this.mapToViewModel(settlements);

    return (
      <div>
        <div className="row">
          <div className="col-md-10">
            <h6>{title}</h6>
          </div>
          <div className="col-md-2" style={{ alignSelf: "flex-end" }}>
            <button
              style={{ float: "right" }}
              className="btn btn-sm btn-secondary no-print"
              onClick={() => this.downloadToExcel(settlements)}
              disabled={isLoading}
            >
              <FontAwesome name="download" /> {this.props.t("Excel")}
            </button>
          </div>
        </div>
        <Table
          className="table-extra-small"
          columns={this.columns}
          sortColumn={"termName"}
          data={tableData}
          valueProperty="termName"
        />
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(SettlementsTable);
