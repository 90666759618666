import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import { sliderBottom } from "d3-simple-slider";
import Loading from "../../common/components/loading/loading";

const NaturalGasPoolPriceLineGraph = ({
  gasData,
  poolPriceData,
  isLoading,
  initialStartDate,
  initialEndDate,
  title = "AESO 7x24 vs ICE Spot Natural Gas", // Added default title
}) => {
  const svgRef = useRef();
  const sliderRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    if (!svg.node()) {
      return;
    }

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight - 20; // Reduced height for slider
    const margin = { top: 30, right: 40, bottom: 70, left: 50 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .attr("viewBox", `0 0 ${parentWidth} ${parentHeight}`)
      .attr("preserveAspectRatio", "none") // Preserve aspect ratio
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    // Parse dates and average by month
    const formatMonth = d3.timeFormat("%Y-%m");

    const averageByMonth = (data, dateField, valueField, termName) => {
      const groupedData = d3.group(data, (d) => {
        const parsedDate = new Date(d[dateField]);
        if (!parsedDate || d[dateField] === null) {
          console.error(`Failed to parse date: ${d[dateField]}`);
          return null;
        }
        return formatMonth(parsedDate);
      });

      return Array.from(groupedData, ([key, values]) => {
        if (key === null) return null;
        const avg = d3.mean(values, (d) => {
          const value = +d[valueField];
          if (isNaN(value)) {
            console.error(`Invalid ${valueField} value:`, d[valueField]);
            return null;
          }
          return value;
        });

        return {
          marketDate: new Date(key),
          [valueField]: avg,
          termName: termName,
        };
      }).filter((d) => d !== null);
    };

    const averagedGasData = averageByMonth(
      gasData,
      "marketDate",
      "settlePrice",
      "Gas Price"
    );

    const minDate = new Date(
      Math.min(...averagedGasData.map((x) => x.marketDate))
    );

    const averagedPoolPriceData = averageByMonth(
      poolPriceData.filter((x) => new Date(x.marketDate) >= minDate), // Limit the lookback to just those dates for which we have gas prices
      "marketDate",
      "poolPrice",
      "Pool Price"
    );

    const combinedData = [...averagedGasData, ...averagedPoolPriceData];

    // Sort the data so the lines draw properly.
    combinedData.sort((a, b) => a.marketDate - b.marketDate);

    // Group data by termName
    const groupedData = d3.group(combinedData, (d) => d.termName);

    // Set up scales
    let xScale = d3
      .scaleTime()
      .domain(d3.extent(combinedData, (d) => d.marketDate))
      .range([0, innerWidth]);

    const yScalePool = d3
      .scaleLinear()
      .domain([0, d3.max(averagedPoolPriceData, (d) => d.poolPrice)])
      .nice()
      .range([innerHeight, 0]);

    const yScaleGas = d3
      .scaleLinear()
      .domain([0, d3.max(averagedGasData, (d) => d.settlePrice)])
      .nice()
      .range([innerHeight, 0]);

    // Create line generators
    const linePool = d3
      .line()
      .x((d) => xScale(d.marketDate))
      .y((d) => yScalePool(d.poolPrice));

    const lineGas = d3
      .line()
      .x((d) => xScale(d.marketDate))
      .y((d) => yScaleGas(d.settlePrice));

    // Color scale for different terms
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10);

    // Add X axis
    let xAxis = chartSvg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .ticks(d3.timeMonth.every(3))
          .tickFormat(d3.timeFormat("%b %Y"))
      )
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end")
      .style("fill", "grey"); // Explicitly set the fill color to grey

    // Add left Y axis (Pool Price)
    const yAxisPool = chartSvg
      .append("g")
      .attr("class", "y-axis y-axis-pool")
      .call(d3.axisLeft(yScalePool).tickFormat((d) => `$${d.toFixed(2)}`));

    // Add right Y axis (Gas Price)
    const yAxisGas = chartSvg
      .append("g")
      .attr("class", "y-axis y-axis-gas")
      .attr("transform", `translate(${innerWidth},0)`)
      .call(d3.axisRight(yScaleGas).tickFormat((d) => `$${d.toFixed(2)}`));

    // Add gridlines
    const gridlines = chartSvg
      .append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(yScalePool).tickSize(-innerWidth).tickFormat(""))
      .attr("stroke-opacity", 0.1);

    // Draw lines
    const linesGroup = chartSvg.append("g").attr("class", "lines");

    groupedData.forEach((values, key) => {
      if (key === "Pool Price") {
        linesGroup
          .append("path")
          .datum(values)
          .attr("class", "line")
          .attr("fill", "none")
          .attr("stroke", colorScale(key))
          .attr("stroke-width", 2)
          .attr("d", linePool);
      } else if (key === "Gas Price") {
        linesGroup
          .append("path")
          .datum(values)
          .attr("class", "line")
          .attr("fill", "none")
          .attr("stroke", colorScale(key))
          .attr("stroke-width", 2)
          .attr("d", lineGas);
      }
    });

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text(title)
      .attr("fill", "grey");

    // Add Y axis label for Pool Price
    chartSvg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - margin.left)
      .attr("x", 0 - innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("font-size", "14px")
      .text("Pool Price ($/MWh)")
      .attr("fill", "grey");

    // Add Y axis label for Gas Price
    chartSvg
      .append("text")
      .attr("transform", "rotate(90)")
      .attr("y", innerWidth + margin.right - 20)
      .attr("x", innerHeight / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .attr("font-size", "14px")
      .text("Gas Price ($/GJ)")
      .attr("fill", "grey");

    // Add legend
    const legendSpacing = 100;
    const legendLineLength = 20;

    const sortedKeys = Array.from(groupedData.keys()).sort((a, b) => a - b);

    const legend = chartSvg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        `translate(${innerWidth / 2}, ${-margin.top / 2 + 10})`
      )
      .selectAll("g")
      .data(sortedKeys)
      .enter()
      .append("g")
      .attr(
        "transform",
        (d, i) =>
          `translate(${
            i * legendSpacing - ((groupedData.size - 1) * legendSpacing) / 2
          }, 0)`
      );

    legend
      .append("line")
      .attr("x1", -legendLineLength / 2)
      .attr("x2", legendLineLength / 2)
      .attr("stroke", colorScale)
      .attr("stroke-width", 2);

    legend
      .append("text")
      .attr("x", legendLineLength * 2 - 5)
      .attr("y", 3)
      .attr("fill", "grey")
      .text((d) => d);

    // Add slider
    const sliderSvg = d3
      .select(sliderRef.current)
      .attr("width", parentWidth)
      .attr("height", 40)
      .attr("class", "no-print")
      .attr("transform", `translate(0,-20)`); // Adjust vertical position;

    sliderSvg.selectAll("*").remove(); // Clear previous slider

    const sliderWidth = innerWidth; // Make slider even smaller

    const updateChart = (range) => {
      const [startDate, endDate] = range;

      // Filter the data
      const filteredData = Array.from(groupedData).map(([key, values]) => [
        key,
        values.filter(
          (d) => d.marketDate >= startDate && d.marketDate <= endDate
        ),
      ]);

      // Update the xScale domain
      xScale = d3
        .scaleTime()
        .domain([startDate, endDate])
        .range([0, innerWidth]);

      // Update Y scales based on filtered data
      yScalePool.domain([
        0,
        d3.max(
          filteredData
            .filter(([key]) => key === "Pool Price")
            .flatMap(([, values]) => values),
          (d) => d.poolPrice
        ),
      ]);

      yScaleGas.domain([
        0,
        d3.max(
          filteredData
            .filter(([key]) => key === "Gas Price")
            .flatMap(([, values]) => values),
          (d) => d.settlePrice
        ),
      ]);

      const t = d3.transition().duration(50).ease(d3.easeLinear);

      chartSvg
        .select(".x-axis")
        .transition(t)
        .call(
          d3
            .axisBottom(xScale)
            //.ticks(d3.timeMonth.every(3))
            .tickFormat(d3.timeFormat("%b %Y"))
        )
        .selectAll("text")
        .attr("transform", "rotate(-45)")
        .style("text-anchor", "end")
        .style("fill", "grey");

      yAxisPool
        .transition(t)
        .call(d3.axisLeft(yScalePool).tickFormat((d) => `$${d.toFixed(0)}`));

      yAxisGas
        .transition(t)
        .call(d3.axisRight(yScaleGas).tickFormat((d) => `$${d.toFixed(2)}`));

      gridlines
        .transition(t)
        .call(d3.axisLeft(yScalePool).tickSize(-innerWidth).tickFormat(""));

      linesGroup
        .selectAll(".line")
        .data(filteredData)
        .join("path") // Use .join instead of .data().transition()
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", ([key]) => colorScale(key))
        .attr("stroke-width", 2)
        .transition(t)
        .attr("d", ([key, values]) => {
          if (key === "Pool Price") {
            return linePool(values);
          } else if (key === "Gas Price") {
            return lineGas(values);
          }
          return null;
        });
    };

    const throttledUpdateChart = throttle(updateChart, 30);

    const slider = sliderBottom()
      .min(d3.min(combinedData, (d) => d.marketDate))
      .max(d3.max(combinedData, (d) => d.marketDate))
      .width(sliderWidth)
      .tickFormat(d3.timeFormat("%Y-%m-%d"))
      .ticks(5)
      .default([
        initialStartDate || d3.min(combinedData, (d) => d.marketDate),
        initialEndDate || d3.max(combinedData, (d) => d.marketDate),
      ])
      .fill("#2196f3")
      .on("onchange", throttledUpdateChart);

    sliderSvg
      .append("g")
      .attr("transform", `translate(${(parentWidth - sliderWidth) / 2},0)`) // Adjusted vertical position to 0
      .call(slider);

    sliderSvg.selectAll(".slider text").style("fill", "grey");
    sliderSvg.selectAll(".tick text").style("opacity", "0");

    // Call updateChart with initial values
    updateChart([
      initialStartDate || d3.min(combinedData, (d) => d.marketDate),
      initialEndDate || d3.max(combinedData, (d) => d.marketDate),
    ]);
  }, [gasData, poolPriceData, initialStartDate, initialEndDate, title]);

  // Throttle function
  const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  useEffect(() => {
    if (!isLoading && gasData && poolPriceData) {
      renderChart();
    }
  }, [renderChart, isLoading, gasData, poolPriceData]);

  useEffect(() => {
    const handleResize = () => {
      if (!isLoading && gasData && poolPriceData) {
        renderChart();
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (svgRef.current) {
      observer.observe(svgRef.current.parentNode);
    }

    return () => {
      if (svgRef.current) {
        observer.unobserve(svgRef.current.parentNode);
      }
    };
  }, [renderChart, isLoading, gasData, poolPriceData]);

  if (isLoading ?? true) return <Loading />;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <svg ref={svgRef} style={{ width: "100%" }}></svg>
      <svg ref={sliderRef} style={{ width: "100%" }}></svg>
    </div>
  );
};

export default NaturalGasPoolPriceLineGraph;
