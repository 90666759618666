import React from "react";
import Loading from "../../common/components/loading/loading";
import { withTranslation } from "react-i18next";
import Input from "../../common/components/form/input";
import Form from "../../common/components/form/form";
import Table from "../../common/components/table/table";
import Joi from "joi-browser";
import {
  getSubmittedData,
  getLatestSettlementSubmissionTime,
  processData,
} from "../../common/services/marketdata/ngxSettlementService";
import { getExternalDataSourcesByMarketId } from "../../common/services/marketdata/externalDataSourceService";
import { getUrlsByExternalDataSourceId } from "../../common/services/marketdata/externalDataSourceUrlService";
import { formatDateTimeWithTimeZone } from "../../common/services/utilities";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Select from "../../common/components/form/select";

class ExternalData extends Form {
  state = {
    data: {
      externalDataSourceId: null,
      marketDate: null,
      rawData: "",
      dateAdded: null,
    },
    errors: {},
    externalDataSources: [],
    externalDataSourceUrls: [],
    submittedData: [],
    isLoading: true,
    showInfoModal: false,
    showSubmittedDataModal: false,
    allowEdit: false,
    scrapedData: null,
  };

  schema = {
    marketDate: Joi.date(),
    externalDataSourceId: Joi.number().required(),
    rawData: Joi.string().required(this.props.t("Data")),
    dateAdded: Joi.string().allow(null, ""),
  };

  async componentDidMount() {
    const { marketId } = this.props;

    const date = new Date();

    //TODO: Implement DATA-529 to make the default date dynamic
    date.setDate(date.getDate() - 1);
    const marketDate = date.toLocaleDateString("fr-CA");

    const externalDataSources = await getExternalDataSourcesByMarketId(
      marketId
    );

    const externalDataSourceId =
      externalDataSources.length > 0 ? externalDataSources[0].id : null;

    const externalDataSourceUrls =
      externalDataSourceId != null
        ? await this.getExternalSourceUrls(externalDataSourceId)
        : [];

    const dateAdded = await this.getLatestSubmissionTime(
      externalDataSourceId,
      marketDate
    );

    const submittedData = await this.fetchSubmittedData(
      externalDataSourceId,
      marketDate
    );

    this.setState({
      data: {
        ...this.state.data,
        marketDate: marketDate,
        externalDataSourceId: externalDataSourceId,
        dateAdded: dateAdded,
        rawData: "",
      },
      externalDataSources: externalDataSources,
      externalDataSourceUrls: externalDataSourceUrls,
      submittedData: submittedData,
      isLoading: false,
    });
  }

  getExternalSourceUrls = async (externalDataSourceId) =>
    await getUrlsByExternalDataSourceId(externalDataSourceId);

  handleMarketDateChange = async (marketDate) => {
    const { externalDataSourceId } = this.state.data;

    const dateAdded = await this.getLatestSubmissionTime(
      externalDataSourceId,
      marketDate
    );

    const submittedData = await this.fetchSubmittedData(
      externalDataSourceId,
      marketDate
    );

    this.setState({
      data: {
        ...this.state.data,
        marketDate,
        dateAdded: formatDateTimeWithTimeZone(dateAdded, "America/Denver"),
      },
      submittedData,
    });
  };

  handleExternalDataSourceChange = async (id) => {
    const { marketDate } = this.state.data;

    const dateAdded = await this.getLatestSubmissionTime(id, marketDate);

    const externalDataSourceUrls = await this.getExternalSourceUrls(id);

    const submittedData = await this.fetchSubmittedData(id, marketDate);

    this.setState({
      data: {
        ...this.state.data,
        externalDataSourceId: id,
        dateAdded: formatDateTimeWithTimeZone(dateAdded, "America/Denver"),
        rawData: "",
      },
      externalDataSourceUrls: externalDataSourceUrls,
      submittedData: submittedData,
    });
  };

  fetchSubmittedData = async (externalDataSourceId, marketDate) => {
    try {
      return await getSubmittedData(externalDataSourceId, marketDate);
    } catch (error) {
      console.error("Error fetching submitted data:", error);
    }
  };

  getLatestSubmissionTime = async (externalDataSourceId, marketDate) => {
    let dateAdded = await getLatestSettlementSubmissionTime(
      externalDataSourceId,
      marketDate
    );

    return dateAdded.data
      ? formatDateTimeWithTimeZone(dateAdded.data, "America/Denver")
      : null;
  };

  handlePaste = (event) => {
    const data = { ...this.state.data, rawData: event.target.value };
    this.setState({ data });
  };

  handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    this.doSubmit(); // Call the doSubmit method you've already defined
  };

  handleInfoModalClose = () => {
    this.setState({ showInfoModal: false });
  };

  handleSubmittedDataModalClose = () => {
    this.setState({ showSubmittedDataModal: false });
  };

  doSubmit = async () => {
    try {
      const dateAdded = new Date().toISOString();

      await processData({ ...this.state.data, dateAdded });

      const { externalDataSourceId, marketDate } = this.state.data;
      const submittedData = await this.fetchSubmittedData(
        externalDataSourceId,
        marketDate
      );

      const updatedData = {
        ...this.state.data,
        dateAdded: formatDateTimeWithTimeZone(dateAdded, "America/Denver"),
        rawData: "",
      };

      this.setState({ data: updatedData, submittedData });

      toast.success(
        this.props.t("SuccessfullySubmittedDataForN", {
          dataType: this.props.t("NgxSettlement"),
        })
      );
    } catch (ex) {
      console.log(
        this.props.t("FailedToSubmitDataForN", {
          dataType: this.props.t("NgxSettlement"),
        })
      );

      if (ex.response && ex.response.status === 400) {
        var errorList = ex.response.data.errors;
        if (errorList) {
          var errorKeys = Object.keys(ex.response.data.errors);
          errorKeys.forEach(function (key) {
            errorList[key].map((error) => {
              toast.error(
                this.props.t("FailedToSubmitDataForN", {
                  dataType: this.props.t("NgxSettlement"),
                }) +
                  " " +
                  key +
                  ": " +
                  error
              );
              return null;
            });
          });
        } else {
          toast.error(
            this.props.t("FailedToSubmitDataForN", {
              dataType: this.props.t("NgxSettlement"),
            }) +
              " " +
              ex.response.data
          );
        }

        toast.error(ex.response.data.errors.title);
      }
    }
  };

  // TODO: Figure out how to use this method instead of creating a table manually
  renderSubmittedDataTable = (submittedData) => {
    const columns = [
      { path: "ngxMarket", label: "NGX Market", className: "text-center" },
      {
        path: "beginDate",
        label: "Begin Date",
        className: "text-end",
        content: (item) => new Date(item.beginDate).toLocaleDateString(),
      },
      {
        path: "endDate",
        label: "End Date",
        className: "text-end",
        content: (item) => new Date(item.endDate).toLocaleDateString(),
      },
      {
        path: "numberOfTrades",
        label: "Number of Trades",
        className: "text-end",
      },
      {
        path: "totalVolume",
        label: "Total Volume",
        className: "text-end",
        content: (item) => item.totalVolume.toFixed(2),
      },
      {
        path: "openPrice",
        label: "Open Price",
        className: "text-end",
        content: (item) =>
          item.openPrice !== null ? item.openPrice.toFixed(4) : "N/A",
      },
      {
        path: "highPrice",
        label: "High Price",
        className: "text-end",
        content: (item) =>
          item.highPrice !== null ? item.highPrice.toFixed(4) : "N/A",
      },
      {
        path: "lowPrice",
        label: "Low Price",
        className: "text-end",
        content: (item) =>
          item.lowPrice !== null ? item.lowPrice.toFixed(4) : "N/A",
      },
      {
        path: "settlePrice",
        label: "Settle Price",
        className: "text-end",
        content: (item) =>
          item.settlePrice !== null ? item.settlePrice.toFixed(4) : "N/A",
      },
      {
        path: "netOpenInterest",
        label: "Net Open Interest",
        className: "text-end",
      },
    ];

    return (
      <Table
        className="table table-dark table-striped table-hover"
        columns={columns}
        data={this.state.submittedData}
        sortColumn={this.state.sortColumn}
        onSort={this.handleSort}
        valueProperty={(item, index) => `${item.ngxMarket}-${index}`} // Since ngxMarket is not a unique value, the index must be created via a hybrid approach
      />
    );
  };

  render() {
    const {
      isLoading,
      data,
      scrapedData,
      showInfoModal,
      showSubmittedDataModal,
      externalDataSources,
      externalDataSourceUrls,
      submittedData,
    } = this.state;
    const { externalDataSourceId, marketDate } = this.state.data;
    const { t } = this.props;

    if (isLoading) return <Loading />;

    const endDate = new Date().toLocaleDateString("fr-CA");
    const externalDataSourceName = externalDataSources.find(
      (x) => x.id === externalDataSourceId
    )?.name;

    console.log(this.validate());

    return (
      <div className="container-fluid">
        <Modal
          show={showInfoModal}
          size="md"
          onHide={this.handleInfoModalClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton className="bg-dark text-light border-0">
            <div className="d-flex justify-content-between w-100 align-items-center">
              <div className="flex-grow-1 text-center">
                <Modal.Title>
                  {t("SubmitExternalDataHelpModalTitle")}
                </Modal.Title>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="bg-dark text-light">
            <div className="row">
              <div className="col" style={{ textAlign: "center" }}>
                {t("NgxSettlementDataInformation1")}
                <table className="table table-bordered">
                  <br></br>
                  <tbody>
                    {externalDataSourceUrls.map((url, index) => (
                      <tr key={index}>
                        <td>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {url}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div style={{ whiteSpace: "pre-line" }}>
                  {t("NgxSettlementDataInformation2")}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showSubmittedDataModal}
          size="xl"
          onHide={() => this.setState({ showSubmittedDataModal: false })}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton className="bg-dark text-light border-0">
            <div className="d-flex justify-content-between w-100 align-items-center">
              <div className="flex-grow-1 text-center">
                <Modal.Title>
                  {t("SubmittedDataModalTitle", {
                    externalDataSource: externalDataSourceName,
                    date: marketDate,
                  })}
                </Modal.Title>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body
            className="bg-dark text-light"
            style={{
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
            }}
          >
            <table className="table table-dark table-striped table-hover">
              <thead>
                <tr>
                  <th className="text-center">NGX Market</th>
                  <th className="text-center">Begin Date</th>
                  <th className="text-center">End Date</th>
                  <th className="text-center">Number of Trades</th>
                  <th className="text-center">Total Volume</th>
                  <th className="text-center">Open Price</th>
                  <th className="text-center">High Price</th>
                  <th className="text-center">Low Price</th>
                  <th className="text-center">Settle Price</th>
                  <th className="text-center">Net Open Interest</th>
                </tr>
              </thead>
              <tbody>
                {submittedData.map((item, index) => (
                  <tr key={index}>
                    <td className="text-center">{item.ngxMarket}</td>
                    <td className="text-end">
                      {new Date(item.beginDate).toLocaleDateString()}
                    </td>
                    <td className="text-end">
                      {new Date(item.endDate).toLocaleDateString()}
                    </td>
                    <td className="text-end">{item.numberOfTrades}</td>
                    <td className="text-end">{item.totalVolume.toFixed(2)}</td>
                    <td className="text-end">
                      {item.openPrice !== null
                        ? item.openPrice.toFixed(4)
                        : "N/A"}
                    </td>
                    <td className="text-end">
                      {item.highPrice !== null
                        ? item.highPrice.toFixed(4)
                        : "N/A"}
                    </td>
                    <td className="text-end">
                      {item.lowPrice !== null
                        ? item.lowPrice.toFixed(4)
                        : "N/A"}
                    </td>
                    <td className="text-end">
                      {item.settlePrice !== null
                        ? item.settlePrice.toFixed(4)
                        : "N/A"}
                    </td>
                    <td className="text-end">{item.netOpenInterest}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Modal.Body>
        </Modal>

        <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="row">
              <div className="col-md-2" style={{ paddingTop: "10px" }}>
                <Select
                  idField="id"
                  name="name"
                  disabled={Object.keys(externalDataSources).length < 2}
                  value={externalDataSourceId}
                  label={t("ExternalDataSource")}
                  options={externalDataSources}
                  onChange={(e) =>
                    this.handleExternalDataSourceChange(e.currentTarget.value)
                  }
                />
              </div>
              <div className="col-md-2" style={{ paddingTop: "10px" }}>
                <Input
                  type="date"
                  max={endDate}
                  onChange={(e) =>
                    this.handleMarketDateChange(e.currentTarget.value)
                  }
                  value={data.marketDate}
                  label={t("MarketDate")}
                />
              </div>
              <div className="col-md-8 mt-auto">
                <div className="text-end">{t("LastSubmittedDate")}</div>
                <div
                  className="text-end"
                  style={{
                    color: data.dateAdded ? "green" : "yellow",
                    cursor: data.dateAdded ? "pointer" : "default",
                  }}
                  onClick={(e) => {
                    if (data.dateAdded) {
                      e.preventDefault();
                      this.setState({ showSubmittedDataModal: true });
                    }
                  }}
                >
                  {data.dateAdded ? (
                    <>
                      {data.dateAdded}
                      <i
                        className="fa-solid fa-up-right-from-square ms-2 fa-sm"
                        style={{ color: "white" }}
                      ></i>
                    </>
                  ) : (
                    t("NoSubmissionForThisDate")
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ paddingTop: "15px" }}>
                {this.renderTextArea(
                  "rawData",
                  t("Data"),
                  t("NgxSettlementDataPlaceholder"),
                  <button
                    class="no-border no-background"
                    style={{
                      visibility:
                        externalDataSourceUrls.length > 0
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showInfoModal: true });
                    }}
                  >
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                  </button>
                )}
              </div>
              <div
                className="col-md-12 align-right"
                style={{ paddingTop: "15px" }}
              >
                {this.renderButton(t("Submit"), "submit")}
              </div>
            </div>
          </div>
        </form>
        <div className="col-md-10">
          {scrapedData && <pre>{JSON.stringify(scrapedData, null, 2)}</pre>}
        </div>
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(ExternalData);
