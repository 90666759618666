import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import Table from "../../common/components/table/table";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import Loading from "../../common/components/loading/loading";

class AbStorageSummaryTable extends Component {
  columns = [
    { path: "item", className: "table-bold" },
    { path: "date", label: this.props.t("Date") },
    {
      path: "inventory",
      label: this.props.t("Inventory"),
      className: "right-align",
    },
    {
      path: "dailyChange",
      label: this.props.t("DailyChange"),
      content: (data) => (
        <span
          style={{
            color: data.dailyChange >= 0 ? "green" : "red",
          }}
        >
          <FontAwesome
            name={data.dailyChange >= 0 ? "sort-asc" : "sort-desc"}
          />{" "}
          {data.dailyChange}
        </span>
      ),
      className: "right-align",
    },
    {
      path: "percentFull",
      label: this.props.t("PercentFull"),
      className: "right-align",
    },
    {
      path: "capacity",
      label: this.props.t("Capacity"),
      className: "right-align",
    },
  ];

  mapToViewModel(summaryData) {
    const dateFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
    };

    return summaryData.map((data) => ({
      item: data.item,
      date: new Date(data.gasDay).toLocaleDateString(
        "en-US",
        dateFormatOptions
      ),
      inventory: data.inventory.toFixed(1),
      dailyChange: data.dailyChange,
      percentFull: data.percentFull.toFixed(1) + "%",
      capacity: data.capacity,
    }));
  }

  render() {
    const { summaryData, isLoading } = this.props;

    if (isLoading) return <Loading />;

    const tableData = _.orderBy(
      this.mapToViewModel(summaryData),
      "sortOrder",
      "asc"
    );

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <h6>AB Natural Gas Storage (Bcf)</h6>
          </div>
        </div>
        <Table
          style={{ whiteSpace: "normal" }}
          className="table-extra-small"
          columns={this.columns}
          sortColumn={"sortOrder"}
          data={tableData}
          valueProperty="item"
        />
      </div>
    );
  }
}

export default withTranslation(["marketdata"])(AbStorageSummaryTable);
