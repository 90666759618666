import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import { sliderBottom } from "d3-simple-slider";
import Loading from "../../common/components/loading/loading";

const HistoricalHeatRateLineGraph = ({
  gasData,
  poolPriceData,
  isLoading,
  initialStartDate,
  initialEndDate,
}) => {
  const svgRef = useRef();
  const sliderRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    if (!svg.node()) {
      return;
    }

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight - 20; // Further reduce slider height
    const margin = { top: 30, right: 10, bottom: 50, left: 30 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .attr("viewBox", `0 0 ${parentWidth} ${parentHeight}`)
      .attr("preserveAspectRatio", "none") // Preserve aspect ratio
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    const formatMonth = d3.timeFormat("%Y-%m");

    const calculateHeatRate = (
      data,
      dateField,
      poolPriceField,
      settlePriceField
    ) => {
      const groupedData = d3.group(data, (d) => {
        const parsedDate = new Date(d[dateField]);
        if (!parsedDate || d[dateField] === null) {
          console.error(`Failed to parse date: ${d[dateField]}`);
          return null;
        }
        return formatMonth(parsedDate);
      });

      return Array.from(groupedData, ([key, values]) => {
        if (key === null) return null;
        const avgPoolPrice = d3.mean(values, (d) => +d[poolPriceField]);
        const avgSettlePrice = d3.mean(values, (d) => +d[settlePriceField]);
        return {
          marketDate: new Date(key),
          heatRate: avgPoolPrice / avgSettlePrice,
        };
      }).filter((d) => d !== null && !isNaN(d.heatRate));
    };

    // Combine gasData and poolPriceData based on marketDate
    const combinedData = gasData
      .map((gas) => {
        const pool = poolPriceData.find(
          (pool) => pool.marketDate === gas.marketDate
        );
        return {
          marketDate: new Date(gas.marketDate), // Ensure marketDate is a Date object
          settlePrice: gas.settlePrice,
          poolPrice: pool ? pool.poolPrice : null,
        };
      })
      .filter((d) => d.poolPrice !== null);

    const heatRateData = calculateHeatRate(
      combinedData,
      "marketDate",
      "poolPrice",
      "settlePrice"
    );

    // Sort the heat rate data by marketDate
    heatRateData.sort((a, b) => a.marketDate - b.marketDate);

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(heatRateData, (d) => d.marketDate))
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(heatRateData, (d) => d.heatRate)])
      .nice()
      .range([innerHeight, 0]);

    // Create line generator
    const line = d3
      .line()
      .x((d) => xScale(d.marketDate))
      .y((d) => yScale(d.heatRate));

    // Add X axis
    const xAxis = chartSvg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale));

    // Add Y axis
    const yAxis = chartSvg
      .append("g")
      .attr("class", "y-axis")
      .call(d3.axisLeft(yScale));

    // Add gridlines
    const gridlines = chartSvg
      .append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(yScale).tickSize(-innerWidth).tickFormat(""))
      .attr("stroke-opacity", 0.1);

    // Draw heat rate line
    const heatRateLine = chartSvg
      .append("path")
      .datum(heatRateData)
      .attr("class", "line")
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 2)
      .attr("d", line);

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text("Historical Heat Rate")
      .attr("fill", "grey");

    // Add slider
    const sliderSvg = d3
      .select(sliderRef.current)
      .attr("width", parentWidth)
      .attr("height", 40)
      .attr("class", "no-print")
      .attr("transform", `translate(0,-20)`); // Adjust vertical position;

    sliderSvg.selectAll("*").remove(); // Clear previous slider

    const sliderWidth = innerWidth; // Make slider even smaller

    const updateChart = (range) => {
      const [startDate, endDate] = range;
      const filteredData = heatRateData.filter(
        (d) => d.marketDate >= startDate && d.marketDate <= endDate
      );

      xScale.domain([startDate, endDate]);
      yScale.domain([0, d3.max(filteredData, (d) => d.heatRate)]).nice();

      const t = d3.transition().duration(50).ease(d3.easeLinear);

      xAxis.transition(t).call(d3.axisBottom(xScale));
      yAxis.transition(t).call(d3.axisLeft(yScale));
      gridlines
        .transition(t)
        .call(d3.axisLeft(yScale).tickSize(-innerWidth).tickFormat(""));

      heatRateLine.datum(filteredData).transition(t).attr("d", line);
    };

    const throttledUpdateChart = throttle(updateChart, 30);

    const slider = sliderBottom()
      .min(d3.min(heatRateData, (d) => d.marketDate))
      .max(d3.max(heatRateData, (d) => d.marketDate))
      .width(sliderWidth)
      .tickFormat(d3.timeFormat("%Y-%m-%d"))
      .ticks(5)
      .default([
        initialStartDate || d3.min(heatRateData, (d) => d.marketDate),
        initialEndDate || d3.max(heatRateData, (d) => d.marketDate),
      ])
      .fill("#2196f3")
      .on("onchange", throttledUpdateChart);

    sliderSvg
      .append("g")
      .attr("transform", `translate(${(parentWidth - sliderWidth) / 2},0)`) // Adjusted vertical position to 0
      .call(slider);

    sliderSvg.selectAll(".slider text").style("fill", "grey");
    sliderSvg.selectAll(".tick text").style("opacity", "0");

    // Call updateChart with initial values
    updateChart([
      initialStartDate || d3.min(heatRateData, (d) => d.marketDate),
      initialEndDate || d3.max(heatRateData, (d) => d.marketDate),
    ]);
  }, [gasData, poolPriceData, initialStartDate, initialEndDate]);

  // Throttle function
  const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  useEffect(() => {
    if (!isLoading) {
      renderChart();
    }
  }, [renderChart, isLoading]);

  useEffect(() => {
    const handleResize = () => {
      if (!isLoading) {
        renderChart();
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (svgRef.current) {
      observer.observe(svgRef.current.parentNode);
    }

    return () => {
      if (svgRef.current) {
        observer.unobserve(svgRef.current.parentNode);
      }
    };
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <svg ref={svgRef} style={{ width: "100%" }}></svg>
      <svg ref={sliderRef} style={{ width: "100%" }}></svg>
    </div>
  );
};

export default HistoricalHeatRateLineGraph;
