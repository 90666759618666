import React, { useEffect, useRef, useCallback } from "react";
import * as d3 from "d3";
import { sliderBottom } from "d3-simple-slider";
import Loading from "../../common/components/loading/loading";

const HistoricalSettlementsGraph = ({
  data,
  title,
  isLoading,
  initialStartDate,
  initialEndDate,
}) => {
  const svgRef = useRef();
  const sliderRef = useRef();

  const renderChart = useCallback(() => {
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    if (!svg.node()) {
      return;
    }

    // Calculate dimensions based on the size of the parent container
    const parentContainer = svg.node().parentNode;
    const parentWidth = parentContainer.clientWidth;
    const parentHeight = parentContainer.clientHeight - 20; // Further reduce slider height
    const margin = { top: 30, right: 10, bottom: 50, left: 50 };

    // Set dimensions considering margins
    const innerWidth = parentWidth - margin.left - margin.right;
    const innerHeight = parentHeight - margin.top - margin.bottom;

    // Create SVG
    const chartSvg = svg
      .attr("width", parentWidth)
      .attr("height", parentHeight)
      .attr("viewBox", `0 0 ${parentWidth} ${parentHeight}`)
      .attr("preserveAspectRatio", "none") // Preserve aspect ratio
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    data.forEach((d) => {
      d.marketDate = new Date(d.marketDate);
    });

    //sort the data so the lines draw properly.
    data.sort((a, b) => a.marketDate - b.marketDate);

    // Group data by termName
    const groupedData = d3.group(data, (d) => d.termName);

    // Set up scales
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data, (d) => d.marketDate))
      .range([0, innerWidth]);

    const yScale = d3
      .scaleLinear()
      .domain([
        d3.min(data, (d) => d.settlePrice),
        d3.max(data, (d) => d.settlePrice),
      ])
      .nice()
      .range([innerHeight, 0]);

    // Create line generator
    const line = d3
      .line()
      .x((d) => xScale(d.marketDate))
      .y((d) => yScale(d.settlePrice));

    const sortedKeys = Array.from(groupedData.keys()).sort((a, b) =>
      String(a).localeCompare(String(b))
    );

    // Color scale for different terms
    const colorScale = d3.scaleOrdinal(d3.schemeCategory10).domain(sortedKeys);

    // Add X axis
    const xAxis = chartSvg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(xScale));

    // Add Y axis
    const yAxis = chartSvg
      .append("g")
      .attr("class", "y-axis")
      .call(d3.axisLeft(yScale).tickFormat((d) => `$${d.toFixed(2)}`));

    // Add gridlines
    const gridlines = chartSvg
      .append("g")
      .attr("class", "grid")
      .call(d3.axisLeft(yScale).tickSize(-innerWidth).tickFormat(""))
      .attr("stroke-opacity", 0.1);

    // Draw lines
    const linesGroup = chartSvg.append("g").attr("class", "lines");

    groupedData.forEach((values, key) => {
      linesGroup
        .append("path")
        .datum(values)
        .attr("class", "line")
        .attr("fill", "none")
        .attr("stroke", colorScale(key))
        .attr("stroke-width", 2)
        .attr("d", line);
    });

    // Add title
    chartSvg
      .append("text")
      .attr("x", innerWidth / 2)
      .attr("y", -margin.top / 2)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "bold")
      .text(title)
      .attr("fill", "grey");

    // Add legend
    const legendSpacing = 100;
    const legendLineLength = 20;

    const legend = chartSvg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "middle")
      .attr(
        "transform",
        `translate(${innerWidth / 2}, ${-margin.top / 2 + 10})`
      )
      .selectAll("g")
      .data(sortedKeys)
      .enter()
      .append("g")
      .attr(
        "transform",
        (d, i) =>
          `translate(${
            i * legendSpacing - ((groupedData.size - 1) * legendSpacing) / 2
          }, 0)`
      );

    legend
      .append("line")
      .attr("x1", -legendLineLength / 2)
      .attr("x2", legendLineLength / 2)
      .attr("stroke", colorScale)
      .attr("stroke-width", 2);

    legend
      .append("text")
      .attr("x", legendLineLength * 2 - 5)
      .attr("y", 3)
      .attr("fill", "grey")
      .text((d) => d);

    // Add slider
    const sliderSvg = d3
      .select(sliderRef.current)
      .attr("width", parentWidth)
      .attr("preserveAspectRatio", "none") // Preserve aspect ratio
      .attr("class", "no-print")
      .attr("transform", `translate(0,-20)`); // Adjust vertical position;

    sliderSvg.selectAll("*").remove(); // Clear previous slider

    const sliderWidth = innerWidth; // Make slider even smaller

    const updateChart = (range) => {
      const [startDate, endDate] = range;
      const filteredData = Array.from(groupedData, ([key, values]) => [
        key,
        values.filter(
          (d) => d.marketDate >= startDate && d.marketDate <= endDate
        ),
      ]);

      xScale.domain([startDate, endDate]);

      // Update Y scale based on filtered data
      yScale
        .domain([
          d3.min(filteredData, ([, values]) =>
            d3.min(values, (d) => d.settlePrice)
          ),
          d3.max(filteredData, ([, values]) =>
            d3.max(values, (d) => d.settlePrice)
          ),
        ])
        .nice();

      const t = d3.transition().duration(50).ease(d3.easeLinear);

      xAxis.transition(t).call(d3.axisBottom(xScale));
      yAxis
        .transition(t)
        .call(d3.axisLeft(yScale).tickFormat((d) => `$${d.toFixed(2)}`));
      gridlines
        .transition(t)
        .call(d3.axisLeft(yScale).tickSize(-innerWidth).tickFormat(""));

      linesGroup
        .selectAll(".line")
        .data(filteredData)
        .transition(t)
        .attr("d", ([, values]) => line(values));
    };

    const throttledUpdateChart = throttle(updateChart, 30);

    const slider = sliderBottom()
      .min(d3.min(data, (d) => d.marketDate))
      .max(d3.max(data, (d) => d.marketDate))
      .width(sliderWidth)
      .tickFormat(d3.timeFormat("%Y-%m-%d"))
      .ticks(5)
      .default([
        initialStartDate || d3.min(data, (d) => d.marketDate),
        initialEndDate || d3.max(data, (d) => d.marketDate),
      ])
      .fill("#2196f3")
      .on("onchange", throttledUpdateChart);

    sliderSvg
      .append("g")
      .attr("transform", `translate(${(parentWidth - sliderWidth) / 2},0)`) // Adjusted vertical position to 0
      .call(slider);

    sliderSvg.selectAll(".slider text").style("fill", "grey");
    sliderSvg.selectAll(".tick text").style("opacity", "0");

    // Call updateChart with initial values
    updateChart([
      initialStartDate || d3.min(data, (d) => d.marketDate),
      initialEndDate || d3.max(data, (d) => d.marketDate),
    ]);
  }, [data, title, initialStartDate, initialEndDate]);

  // Throttle function
  const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };

  useEffect(() => {
    if (!isLoading) {
      renderChart();
    }
  }, [renderChart, isLoading]);

  useEffect(() => {
    const handleResize = () => {
      if (!isLoading) {
        renderChart();
      }
    };

    const observer = new ResizeObserver(handleResize);
    if (svgRef.current) {
      observer.observe(svgRef.current.parentNode);
    }

    return () => {
      if (svgRef.current) {
        observer.unobserve(svgRef.current.parentNode);
      }
    };
  }, [renderChart, isLoading]);

  if (isLoading ?? true) return <Loading />;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <svg ref={svgRef} style={{ width: "100%" }}></svg>
      <svg ref={sliderRef} style={{ width: "100%" }}></svg>
    </div>
  );
};

export default HistoricalSettlementsGraph;
